import { html } from "lit";
export const accountIcon = html`<svg
  class="dile-icon"
  xmlns="http://www.w3.org/2000/svg"
  enable-background="new 0 0 24 24"
  height="24px"
  viewBox="0 0 24 24"
  width="24px"
  fill="#000000"
>
  <g><rect fill="none" height="24" width="24" /></g>
  <g>
    <path
      d="M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,6c1.93,0,3.5,1.57,3.5,3.5S13.93,13,12,13 s-3.5-1.57-3.5-3.5S10.07,6,12,6z M12,20c-2.03,0-4.43-0.82-6.14-2.88C7.55,15.8,9.68,15,12,15s4.45,0.8,6.14,2.12 C16.43,19.18,14.03,20,12,20z"
    />
  </g>
</svg>`;
